import React, { useState } from 'react'
import './index.scss'
import IOSView from './IOS'
import AndroidView from './Android'

const SMSPreview = ({ data, platform }) => {
  return (
    <div className="SMSPreviewComponent">
      {platform === 'ios' && <IOSView data={data}></IOSView>}
      {platform === 'android' && <AndroidView data={data} />}
    </div>
  )
}

export default SMSPreview
