import axios from 'Utils/axios'
import { useStoreState } from 'easy-peasy'
import React, { useState } from 'react'

export default function useTestZaloGroup() {
  const { selected_channel, selected_placement, audience_id, content, zalo_groups } = useStoreState(
    (state) => state.campaignModule
  )
  const [selectedGroups, setSelectedGroups] = useState<string[]>(zalo_groups)
  const [loading, setLoading] = useState(false)

  const handleTest = async () => {
    setLoading(true)
    const payload = {
      channel_id: selected_channel?.id,
      placement_id: selected_placement?.id,
      audience_segment_id: audience_id,
      whitelist: selectedGroups.map((group) => ({ zalo_group_code_name: group })),
      content: content,
    }
    try {
      await axios.post('/tests/campaigns/send-notification', payload)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  return {
    handleTest,
    selectedGroups,
    zalo_groups,
    loading,
    setSelectedGroups,
  }
}
