export const DEVICES = [
  {
    name: 'IPhone X',
    code: 'x',
    os: 'ios',
  },
  {
    name: 'IPhone 11',
    code: 'ip11',
    os: 'ios',
  },
  {
    name: 'IPhone XS Max',
    code: 'xsm',
    os: 'ios',
  },
  {
    name: 'iPhone 13 Pro Max',
    code: 'pmx13',
    os: 'ios',
  },
  {
    name: 'Samsung Galaxy Note 9',
    code: 'note9',
    os: 'android',
  },
  {
    name: 'Google Pixel',
    code: 'gpx',
    os: 'android',
  },
]

export const DEVICES_STATUS = [
  {
    key: 'lock_screen',
    title: 'Lock screen',
  },
  {
    key: 'un_lock',
    title: 'Unlock',
  },
  {
    key: 'detail',
    title: 'Detail',
  },
]

export const DEVICE_INFO = {
  x: {
    width: 375,
    height: 812,
    widthContent: 355,
  },
  ip11: {
    width: 390,
    height: 844,
    widthContent: 370,
  },
  xsm: {
    width: 414,
    height: 896,
    widthContent: 394,
  },
  pmx13: {
    width: 428,
    height: 926,
    widthContent: 408,
  },
  note9: {
    width: 360,
    height: 740,
    widthContent: 330,
  },
  gpx: {
    width: 412,
    height: 732,
    widthContent: 380,
  },
}
