import { Button, Checkbox, Input, message, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'Utils/axios'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { getCampaignDynamicParams } from 'Services/campaign-dynamic-params'

export default function useTestOnDevice({ onSubmit }) {
  const {
    selected_channel,
    selected_placement,
    audience_id,
    id,
    content,
    dynamic_param_dimensions,
  } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)
  const [open, setOpen] = useState(false)
  const [devices, setDevices] = useState<{ phone: string; selected: boolean }[]>([])
  const [customerPhones, setCustomerPhones] = useState<string[]>([])
  const [customerPhoneSelected, setCustomerPhoneSelected] = useState<string>('')
  const [input, setInput] = useState('')
  const [customerPhoneInput, setCustomerPhoneInput] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const handleGetCampaignDynamicParams = async () => {
    const resp = await getCampaignDynamicParams(audience_id, id)
    setState({
      key: 'dynamic_param_dimensions',
      value:
        JSON?.stringify({
          ...resp?.data?.filter((item) => item?.group_condition)?.[0]?.group_condition,
          segmentation_type:
            resp?.data?.filter((item) => item?.group_condition)?.[0]?.segmentation_type || null,
        }) || '[]',
    })
  }

  useEffect(() => {
    handleGetCampaignDynamicParams()
  }, [])

  const onAddDevice = (phone: string) => {
    setDevices((prev) => [...prev, { phone, selected: true }])
  }

  const onAddCustomerPhone = (phone: string) => {
    setCustomerPhones((prev) => [...prev, phone])
    setCustomerPhoneSelected(phone)
  }

  const onRemove = (index: number) => {
    const _devices = [...devices]
    _devices.splice(index, 1)
    setDevices(_devices)
  }

  const onRemoveCustomerPhone = (index: number, value: string) => {
    if (customerPhoneSelected === value) {
      setCustomerPhoneSelected('')
    }
    const _customerPhones = [...customerPhones]
    _customerPhones.splice(index, 1)
    setCustomerPhones(_customerPhones)
  }

  const onSelect = (newValue: { phone: string; selected: boolean }, index: number) => {
    const _devices = [...devices]
    _devices.splice(index, 1, newValue)
    setDevices(_devices)
  }

  const onCheckCustomerPhone = (newValue: string) => {
    setCustomerPhoneSelected(newValue)
  }

  const handleTest = async () => {
    let dynamic_param_dimensions_payload
    try {
      const parsedDynamicParams = JSON.parse(
        dynamic_param_dimensions || '[{"segmentation_type": null}]'
      )
      dynamic_param_dimensions_payload = Array.isArray(parsedDynamicParams)
        ? JSON.stringify(parsedDynamicParams)
        : JSON.stringify([parsedDynamicParams])
    } catch (error) {
      console.error('Error parsing dynamic_param_dimensions:', error)
      return
    }

    const payload = {
      channel_id: selected_channel?.id,
      placement_id: selected_placement?.id,
      audience_segment_id: audience_id,
      whitelist:
        devices?.filter((device) => device.selected).map((device) => ({ phone: device.phone })) ||
        [],
      content: content,
      customer_data_phone: customerPhoneSelected,
      ...(JSON.parse(dynamic_param_dimensions_payload)[0]?.segmentation_type !== null
        ? { dynamic_param_dimensions: dynamic_param_dimensions_payload }
        : {}),
    }

    if (onSubmit) {
      onSubmit(payload)
      return
    }

    try {
      const res = await axios.post('/tests/campaigns/send-notification', payload)

      console.log(res)
    } catch (err) {
      console.log(err)
    }
  }

  return {
    devices,
    handleTest,
    onSelect,
    onAddDevice,
    onRemove,
    setOpen,
    open,
    setInput,
    input,
    setIsEditing,
    isEditing,
    customerPhones,
    onAddCustomerPhone,
    onRemoveCustomerPhone,
    customerPhoneSelected,
    onCheckCustomerPhone,
    customerPhoneInput,
    setCustomerPhoneInput,
  }
}
