import React from 'react'
import './index.scss'
import RankingGold from 'Assets/images/icons/RankingGold.png'
import RankingSilver from 'Assets/images/icons/RankingSilver.png'
import RankingBronze from 'Assets/images/icons/RankingBronze.png'
import kFormatter from 'Utils/kFormatter'
import { Empty, Skeleton } from 'antd'
import { generateColor, shortName } from 'Utils'
import classNames from 'classnames'

export type RankListType = {
  name: string
  id: number
  value: number
  avatar?: string
  unit?: any
}

export type RankingType = {
  title: string
  list: RankListType[]
  avatars?: string[]
  subtitle?: string
  currentRank?: any
  size?: string
  type?:
    | 'top_rev_staff_by_store'
    | 'top_rev_staff_by_org'
    | 'top_rev_store'
    | 'top_performance_store'
    | string
}

const LeaderItem = ({ leader, className = '', medal }) => {
  if (!leader) return <></>
  const renderAvatar = () => {
    if (!leader.avatar)
      return (
        <div
          className="avatar-char text-xl font-semibold"
          style={{ backgroundColor: generateColor(leader.name) }}
        >
          {shortName(leader.name)}
        </div>
      )
    return <img className="avatar" src={leader.avatar}></img>
  }
  return (
    <div className={`leader-item ${className}`}>
      <div className="avatar-wrapper">
        {renderAvatar()}
        <img className="medal" src={medal}></img>
      </div>
      <span className="full-name px-1 text-center my-2 word-break">{leader.name}</span>
      <span className="full-name px-1 text-center font-semibold">{kFormatter(leader.value)}</span>
    </div>
  )
}

const RankingCard: React.FC<RankingType> = ({
  title,
  list,
  subtitle,
  type,
  currentRank,
  size = 'medium',
}) => {
  const subtileBackground = () => {
    if (type === 'top_rev_staff_by_org') return ''
    else return 'blue'
  }
  if (!list)
    return (
      <div className="bg-white p-6">
        <Skeleton></Skeleton>
      </div>
    )
  if (!list.length)
    return (
      <div className="bg-white p-6">
        <Empty></Empty>
      </div>
    )
  return (
    <div className={`ranking-card ${size}`}>
      <h3 className="title mb-2 mt-6">{title}</h3>
      <span className={`subtitle font-semibold mb-2 text-center ${subtileBackground()}`}>
        {subtitle}
      </span>
      <div className="leaders-container px-4">
        <LeaderItem leader={list[1]} medal={RankingSilver}></LeaderItem>
        <LeaderItem leader={list[0]} className="top-1" medal={RankingGold}></LeaderItem>
        <LeaderItem leader={list[2]} medal={RankingBronze}></LeaderItem>
      </div>
      <div className="user-list">
        {list.splice(3, list.length).map((item, index) => (
          <div
            key={index}
            className={classNames('user-item px-3 py-2 shadow-div_t', {
              me: item.id === currentRank?.id,
            })}
          >
            <div className="flex">
              <span className="__rank">{index + 4}</span>
              <span className="__name">{item.name}</span>
            </div>
            <span className="__number">{kFormatter(item.value)}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RankingCard
