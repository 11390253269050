import { Button, Checkbox, Input, Modal, Radio, Spin } from 'antd'
import React from 'react'
import { RiAddBoxLine, RiAddCircleLine, RiDeleteBin6Line } from 'react-icons/ri'

import { RiSmartphoneLine } from 'react-icons/ri'
import useTestOnDevice from './useTestOnDevice'
import TestZaloGroup from './TestZaloGroup'

export default function TestOnDevice({
  isZaloGroup,
  onSubmit,
  loading,
}: {
  isZaloGroup?: boolean
  onSubmit?: (payload: Record<string, string>) => void
  loading?: boolean
}) {
  const {
    devices,
    handleTest,
    onSelect,
    onAddDevice,
    onRemove,
    setOpen,
    open,
    setInput,
    input,
    customerPhones,
    onAddCustomerPhone,
    onRemoveCustomerPhone,
    customerPhoneSelected,
    onCheckCustomerPhone,
    customerPhoneInput,
    setCustomerPhoneInput,
  } = useTestOnDevice({ onSubmit })

  return (
    <div>
      <div
        style={{
          color: 'var(--brand_primary)',
          fontSize: 14,
          fontWeight: 600,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          gap: '4px',
          alignItems: 'center',
        }}
        onClick={() => {
          setOpen(true)
        }}
      >
        <RiSmartphoneLine />
        Test on Device
      </div>
      <Modal
        footer={null}
        title={
          <div className="flex flex-col gap-2">
            <div className="text-lg font-semibold">Test on Device</div>
            <div className="text-sm text-gray-500" style={{ maxWidth: '288px' }}>
              Input 1 phone number used for testing and 1 for getting content from segment.
            </div>
          </div>
        }
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        visible={open}
        width={360}
      >
        {(() => {
          if (isZaloGroup) return <TestZaloGroup />

          return (
            <div style={{ maxHeight: 500, overflowY: 'auto' }}>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Input
                  placeholder="Input test phone number"
                  style={{ marginRight: '8px' }}
                  value={input}
                  onChange={(e) => {
                    const { value: inputValue } = e.target
                    const reg = /^-?\d*(\.\d*)?$/
                    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
                      setInput(inputValue)
                    }
                  }}
                />
                <Button
                  className="p-0 cursor-pointer"
                  style={{
                    width: 20,
                    color: '#000',
                    fontWeight: 600,
                  }}
                  disabled={!input}
                  type="text"
                  onClick={() => {
                    onAddDevice(input)
                    setInput('')
                  }}
                >
                  <RiAddCircleLine size={20} style={{ color: '#000' }} />
                </Button>
              </div>

              {devices?.length ? (
                <div>
                  {devices.map((device, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          padding: '8px 0',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderBottom: '1px solid black',
                          borderColor: '#F0F0F0',
                        }}
                      >
                        <div>
                          <Checkbox
                            checked={device.selected}
                            onChange={(e) => {
                              onSelect({ phone: device.phone, selected: e.target.checked }, index)
                            }}
                          />
                          <span style={{ paddingLeft: '8px' }}>{device.phone}</span>
                        </div>
                        <div className="cursor-pointer">
                          <RiDeleteBin6Line
                            onClick={() => {
                              onRemove(index)
                            }}
                            style={{ width: 18, height: 18 }}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : null}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '16px',
                }}
              >
                <Input
                  placeholder="Input get content phone number"
                  style={{ marginRight: '8px' }}
                  value={customerPhoneInput}
                  onChange={(e) => {
                    const { value: inputValue } = e.target
                    const reg = /^-?\d*(\.\d*)?$/
                    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
                      setCustomerPhoneInput(inputValue)
                    }
                  }}
                />
                <Button
                  className="p-0 cursor-pointer"
                  style={{
                    width: 20,
                    color: '#000',
                    fontWeight: 600,
                  }}
                  disabled={!customerPhoneInput}
                  type="text"
                  onClick={() => {
                    onAddCustomerPhone(customerPhoneInput)
                    setCustomerPhoneInput('')
                  }}
                >
                  <RiAddCircleLine size={20} style={{ color: '#000' }} />
                </Button>
              </div>

              {customerPhones?.length ? (
                <Radio.Group
                  onChange={(e) => onCheckCustomerPhone(e.target.value)}
                  value={customerPhoneSelected}
                  className="flex flex-col gap-2"
                >
                  {customerPhones.map((device, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          padding: '8px 0',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderBottom: '1px solid black',
                          borderColor: '#F0F0F0',
                        }}
                      >
                        <Radio
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            if (device === customerPhoneSelected) {
                              onCheckCustomerPhone('')
                            }
                          }}
                          value={device}
                        >
                          {device}
                        </Radio>
                        <div className="cursor-pointer">
                          <RiDeleteBin6Line
                            onClick={() => {
                              onRemoveCustomerPhone(index, device)
                            }}
                            style={{ width: 18, height: 18 }}
                          />
                        </div>
                      </div>
                    )
                  })}
                </Radio.Group>
              ) : null}
              <Button
                onClick={handleTest}
                style={{ marginTop: '20px' }}
                block
                size="large"
                disabled={!devices?.length || !devices?.filter((device) => device.selected)?.length}
                type="primary"
                loading={loading}
              >
                {customerPhones?.length && !customerPhoneSelected
                  ? 'Test with random number'
                  : 'Test'}
              </Button>
            </div>
          )
        })()}
      </Modal>
    </div>
  )
}
