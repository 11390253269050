import React from 'react'
import Kpos1 from 'Assets/images/Kpos1.png'
import ScriptLineOne from 'Assets/images/script_line_1.png'
import ScriptLineTwo from 'Assets/images/script_line_2.png'
import './index.scss'
import { thousandsSeparator } from 'Utils/kFormatter'

export default function KposPreview({ data }: { data?: any }) {
  const memoPreview = React.useMemo(() => {
    const script_line_1 =
      data?.selectedPromotion === 'promotion_1'
        ? data?.promotion_1?.script_lines?.[0]
        : data?.promotion_2?.script_lines?.[0]
    const script_line_2 =
      data?.selectedPromotion === 'promotion_1'
        ? data?.promotion_1?.script_lines?.[1]
        : data?.promotion_2?.script_lines?.[1]
    const isPromotion1Selected = data?.selectedPromotion === 'promotion_1'
    const isPromotion2Selected = data?.selectedPromotion === 'promotion_2'
    return (
      <div className="relative">
        <img src={Kpos1} />
        <div className="absolute bottom-0 left-0">
          <div className="component-container">
            <div className="product-info-wrapper">
              <div
                className="product-info_item_wrapper"
                style={{ border: isPromotion1Selected ? 'solid #52C41A' : undefined }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '-24px',
                    left: '-2px',
                    color: 'white',
                    fontSize: '10px',
                    fontWeight: 600,
                    padding: '4px 8px',
                    borderRadius: ' 0px 4px 0px 0px',
                    display: isPromotion1Selected ? 'block' : 'none',
                    backgroundColor: '#52C41A',
                  }}
                >
                  Promotion 1
                </div>
                {data?.promotion_1?.product?.discount_percent ? (
                  <div className="discount-badge">
                    -{data?.promotion_1?.product?.discount_percent}
                  </div>
                ) : null}
                <div className="product-info">
                  <img src={data?.promotion_1?.product?.thumbnail} alt="product" />
                  <div className="price">
                    <span className="current-price">
                      {thousandsSeparator(data?.promotion_1?.product?.discount_price)} đ
                    </span>
                    <span className="original-price">
                      {thousandsSeparator(data?.promotion_1?.product?.original_price)} đ
                    </span>
                  </div>
                  <div className="product-name line-clamp-2">
                    {data?.promotion_1?.product?.name}
                  </div>
                </div>
              </div>
              {data?.promotion_2?.product?.id ? (
                <div
                  className="product-info_item_wrapper"
                  style={{ border: isPromotion2Selected ? 'solid #52C41A' : undefined }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '-24px',
                      left: '-2px',
                      color: 'white',
                      fontSize: '10px',
                      fontWeight: 600,
                      padding: '4px 8px',
                      borderRadius: ' 0px 4px 0px 0px',
                      display: isPromotion2Selected ? 'block' : 'none',
                      backgroundColor: '#52C41A',
                    }}
                  >
                    Promotion 2
                  </div>
                  {data?.promotion_2?.product?.discount_percent ? (
                    <div className="discount-badge">
                      -{data?.promotion_2?.product?.discount_percent}
                    </div>
                  ) : null}

                  <div className="product-info">
                    <img src={data?.promotion_2?.product?.thumbnail} alt="product" />
                    <div className="price">
                      <span className="current-price">
                        {thousandsSeparator(data?.promotion_2?.product?.discount_price)} đ
                      </span>
                      <span className="original-price">
                        {thousandsSeparator(data?.promotion_2?.product?.original_price)} đ
                      </span>
                    </div>
                    <div className="product-name line-clamp-2">
                      {data?.promotion_2?.product?.name}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="customer-info">
              <div className="customer-info-title">Thông tin khách hàng</div>
              <div className="customer-info-body"></div>
              <div className="script-line script-line-1">
                {script_line_1 || 'Scrip line 1'}
                <img src={ScriptLineOne} className="script-line-1-tail" />
              </div>
              <div className="script-line script-line-2">
                {script_line_2 || 'Scrip line 2'}
                <img src={ScriptLineTwo} className="script-line-2-tail" />
              </div>
            </div>
            <div className="cta-info">
              <div className="cta-button-wrapper">
                <div className="cta-button">CTA</div>
              </div>
              <div className="info-today">
                <div className="flex justify-between">
                  <div>Thưởng hôm nay</div>
                  <div>500K</div>
                </div>
                <div className=" flex justify-between">
                  <div style={{ fontWeight: 400, fontSize: '6px', color: '#718096' }}>
                    So với hôm qua
                  </div>
                  <div className="increase">+25K</div>
                </div>
              </div>
              <div className="info-month">
                <div className=" flex justify-between">
                  <div>Thưởng tháng này</div>
                  <div>1.500K</div>
                </div>
                <div className=" flex justify-between">
                  <div style={{ fontWeight: 400, fontSize: '6px', color: '#718096' }}>
                    So với tháng trước
                  </div>
                  <div className="decrease">-800K</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }, [data])
  return <div>{memoPreview}</div>
}
