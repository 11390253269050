import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import './index.scss'
import { MetricTypeProps } from 'MetricCardModule'
import { formatNum } from 'Utils/kFormatter'
import { capitalizeFirstLetter } from 'Utils'
import { truncate } from 'Utils'

const MetricCard: React.FC<MetricTypeProps> = ({
  mode,
  unit,
  value,
  title,
  style,
  unitDisplay,
  subvalue,
  children,
  info,
  className,
}) => {
  const renderSubValue = () => {
    if (!subvalue) return
    return <span className="text-disabled text-sm">({subvalue})</span>
  }
  const renderUnitDisplay = () => {
    if (!unitDisplay || unit === 'percent') return
    return <span className="text-disabled text-sm">{unitDisplay}</span>
  }

  const kFormatter = (num: number): string => {
    const thousandsSeparator = (num: number): string =>
      num
        ?.toFixed(0)
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    switch (true) {
      case num === 0:
        return '0'
      case !num:
        return 'N/A'
      case num <= -1.0e9:
      case num >= 1.0e9:
        return (num / 1.0e9)?.toFixed(3) + 'B'
      case num <= -1.0e6:
      case num >= 1.0e6:
        return (num / 1.0e6)?.toFixed(3) + 'M'
      case num <= -1.0e3:
      case num >= 1.0e3:
        return thousandsSeparator(num)
      default:
        return num?.toFixed(1)?.toString()
    }
  }

  const thousandsSeparator = (num: number): string =>
    num
      ?.toFixed(0)
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  const formatNumStore360 = (num: string): string => {
    var formatNumber = parseFloat(num)

    if (formatNumber > 1000) {
      return kFormatter(formatNumber)
    }
    // if is float
    if (formatNumber % 1 === 0) {
      return formatNumber?.toString()
    }
    return formatNumber?.toFixed(2)?.toString()
  }
  const renderValue = () => {
    if (!value) return '0'
    if (unit === 'string') return value
    if (new RegExp(/[a-z$&+,:;=?@#|'<>^*()%!-]/g).test(value.toString())) {
      return value
    }

    let suffix = ''
    if (unit === 'percent') suffix = '%'
    if (unit === 'usd_currency') suffix = 'USD'
    if (unit === 'vnd_currency') suffix = 'VNĐ'
    return `${
      window.location.href.split('/')[4] === 'store360'
        ? formatNumStore360(value.toString())
        : formatNum(value.toString())
    } ${suffix}`
  }

  return (
    <div className={`metric-card ${className} --${mode}`} style={style}>
      <div className="title-wrapper">
        <span title={capitalizeFirstLetter(title)} className="title">
          {/* {truncate(capitalizeFirstLetter(title), 20)} */}
          {capitalizeFirstLetter(title)}
        </span>
        {mode !== 'small' && info && (
          <div className="icon ml-2">
            <InfoCircleOutlined />
          </div>
        )}
      </div>
      <span className="value">{renderValue()}</span>

      {children}
      {renderUnitDisplay()}
      {renderSubValue()}
    </div>
  )
}

MetricCard.defaultProps = {
  className: '',
  mode: 'medium',
  style: {},
  unit: 'percentage',
}

export default MetricCard
