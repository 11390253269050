import React, { useState } from 'react'
import ZnsByTemplateId from './ZnsByTemplateId'
import TestOnDevice from '../TestOnDevice'
import axios from 'Utils/axios'
import { message } from 'antd'

export default function ZNSPreview() {
  const [loading, setLoading] = useState(false)
  const handleTest = async (payload) => {
    try {
      setLoading(true)
      const res = await axios.post('/tests/campaigns/send-notification', {
        ...payload,
        content: {
          ...payload.content,
        },
      })
      if (res?.data?.failed_count > 0) {
        message.error(
          `Failed to send test, failed number: ${res?.data?.failed_contacts
            ?.map((contact) => contact.phone)
            .join(', ')}`
        )
      }
      if (res?.data?.success_count > 0) {
        message.success('Test sent successfully')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="w-full" id="ContentPreview">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-lg">Preview</span>
        {/* <div className="platform-select text-brand_primary font-semibold">
            <Select options={platforms} onChange={(value) => setPlatform(value)} value={platform} />
          </div> */}
        <TestOnDevice
          onSubmit={(payload) => {
            handleTest(payload)
          }}
          loading={loading}
        />
      </div>
      <div className="preview-screen">
        <div>{<ZnsByTemplateId />}</div>
      </div>
    </div>
  )
}
