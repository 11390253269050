import React from 'react'
import { DEVICE_INFO } from '../constants'
import StatusBar from 'Assets/images/Dark.png'
import { brandVars } from 'Utils/brandVars'
import { Button } from 'antd'
import { RiCloseLine } from 'react-icons/ri'

const UnlockView = ({
  status,
  device,
  data,
  initAiData,
}: {
  status?: string
  device?: any
  data?: any
  initAiData?: any
}) => {
  const { title, image, content, cta } = data
  const vars = brandVars()
  return (
    <div
      className="relative"
      style={{
        height: DEVICE_INFO[device.code].height,
        width: DEVICE_INFO[device.code].width,
        background: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <div
        className="bg-no-repeat bg-contain"
        style={{ width: '100%', height: '44px', background: `url(${StatusBar})` }}
      ></div>
      <div
        className="p-4 flex flex-col justify-between absolute w-full bottom-0"
        style={{
          background: 'white',
          borderRadius: '12px 12px 0px 0px',
        }}
      >
        <div className="relative">
          <div className="absolute right-0">
            <RiCloseLine
              style={{ background: 'rgba(0, 0, 0, 0.36)' }}
              size={24}
              className="rounded-full p-1 text-white mt-2 mr-2"
            />
          </div>
          <img
            className="w-full"
            src={
              data?.mode === 'generate' || data?.type === 'generate'
                ? data?.productPreview?.image || initAiData?.image
                : image || vars.logo
            }
            alt=""
          />
          <p className="mt-4 mb-3 text-lg font-semibold break-all">
            {data?.mode === 'generate' || data?.type === 'generate'
              ? data?.productPreview?.headline || initAiData?.headline
              : title || data?.productPreview?.headline || 'Title'}
          </p>
          <p className="description whitespace-pre-line break-all">
            {data?.mode === 'generate' || data?.type === 'generate'
              ? data?.productPreview?.body
              : content || initAiData?.body || data?.productPreview?.body || 'content'}
          </p>
        </div>

        <div className={`gap-2 ${cta?.length && 'pt-4'} `}>
          {cta?.map((item, index) => (
            <Button
              type={item?.option === 'primary' ? 'primary' : 'default'}
              size="middle"
              className="w-full mb-2"
            >
              <div className="text-lg font-semibold">
                {data?.mode === 'generate' || data?.type === 'generate'
                  ? data?.productPreview?.cta || initAiData?.cta
                  : item?.title || data?.productPreview?.cta || 'Title'}
              </div>
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default UnlockView
