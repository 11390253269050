import { useStoreState } from 'easy-peasy'
import React from 'react'
import useTestZaloGroup from './useTestZaloGroup'
import { Button, Checkbox } from 'antd'

export default function TestZaloGroup() {
  const { handleTest, selectedGroups, setSelectedGroups, zalo_groups, loading } = useTestZaloGroup()
  return (
    <div>
      {zalo_groups?.map((group) => {
        return (
          <div
            style={{
              padding: '8px 0',
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid black',
              borderColor: '#F0F0F0',
            }}
          >
            <Checkbox
              checked={selectedGroups.includes(group)}
              onChange={(e) => {
                if (selectedGroups.includes(group))
                  setSelectedGroups((prev) => prev.filter((item) => item !== group))
                else setSelectedGroups((prev) => [...prev, group])
              }}
            />
            <span style={{ paddingLeft: '8px' }}>{group}</span>
          </div>
        )
      })}
      <Button
        loading={loading}
        onClick={handleTest}
        style={{ marginTop: '20px' }}
        block
        size="large"
        type="primary"
      >
        Test
      </Button>
    </div>
  )
}
