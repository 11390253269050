import { useStoreState } from 'easy-peasy'
import React from 'react'
import TestOnDevice from '../TestOnDevice'

export default function ZaloGroupReview() {
  const { content } = useStoreState((state) => state.campaignModule)
  return (
    <div className="w-full" id="ContentPreview">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-lg">Preview</span>
        <TestOnDevice isZaloGroup />
      </div>
      <div className="preview-screen">
        <div
          style={{
            width: '400px',
            background: 'white',
            minHeight: '236px',
            whiteSpace: 'pre-wrap',
            padding: '16px',
            lineBreak: 'anywhere',
          }}
        >
          {content.content}
        </div>
      </div>
    </div>
  )
}
