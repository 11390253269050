import React, { useState } from 'react'

import './index.scss'
import { brandVars } from 'Utils/brandVars'
import { Button, Divider, Dropdown, Menu, message, Space } from 'antd'
import { RiArrowDownSLine } from 'react-icons/ri'
import { DEVICES, DEVICES_STATUS } from './constants'
import LockScreenView from './LookScreen'
import UnlockView from './Unlock'
import DetailView from './Detail'
import TestOnDevice from 'Components/PreviewChannels/Preview/TestOnDevice'
import axios from 'Utils/axios'

const OneLifeAppPushPreview = ({ data, initAiData }: { data?: any; initAiData?: any }) => {
  const [loading, setLoading] = useState(false)
  const handleTest = async (payload) => {
    try {
      setLoading(true)
      const res = await axios.post('/tests/campaigns/send-notification', {
        ...payload,
        content: data || initAiData || {},
      })
      if (res?.data?.failed_count > 0) {
        message.error(
          `Failed to send test, failed number: ${res?.data?.failed_contacts
            ?.map((contact) => contact.phone)
            .join(', ')}`
        )
      }
      if (res?.data?.success_count > 0) {
        message.success('Test sent successfully')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }
  const [status, setStatus] = useState('detail')
  const [device, setDevice] = useState({
    name: 'IPhone 11',
    code: 'ip11',
    os: 'ios',
  })

  const menu = (
    <Menu>
      {DEVICES.map((item) => (
        <Menu.Item key={item.code} onClick={() => setDevice(item)}>
          <div>{item.name}</div>
        </Menu.Item>
      ))}
    </Menu>
  )

  const renderContent = (statusCode: string) => {
    switch (statusCode) {
      case 'lock_screen':
        return (
          <LockScreenView status={status} device={device} data={data} initAiData={initAiData} />
        )
        break

      case 'un_lock':
        return <UnlockView status={status} device={device} data={data} initAiData={initAiData} />
        break

      case 'detail':
        return <DetailView status={status} device={device} data={data} initAiData={initAiData} />
        break

      default:
        break
    }
  }
  return (
    <div className="w-full" id="ContentPreview">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-lg">Preview</span>
        <div className="flex gap-1 platform-select items-center text-brand_primary font-semibold">
          {/* <Select options={platforms} onChange={(value) => setPlatform(value)} value={platform} /> */}
          <TestOnDevice onSubmit={handleTest} loading={loading} />
          <Divider type="vertical" style={{ height: 18 }} />
          <Dropdown overlay={menu} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <div>Device: {device.name}</div>
                <RiArrowDownSLine />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
      <div className="flex items-center mt-5">
        {DEVICES_STATUS.map((item, index) => (
          <div
            onClick={() => setStatus(item.key)}
            key={index}
            style={{ borderRadius: 100 }}
            className={` py-2 px-3 mr-2 text-base cursor-pointer inline-block font-semibold ${
              item.key === status
                ? 'bg-brand_light text-brand_primary'
                : ' bg-gray-50 text-secondary_text'
            }`}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className="preview-screen">
        <div>
          <div className="AppPushPreviewComponent">
            <div className={``}>
              <div className="window-preview">
                {renderContent(status)}
                {/* <div className="p-4">
                  <img className="max-w-[343px]" src={image || vars.logo} alt="" />
                  <p className="mt-4 mb-3 text-lg font-semibold">{title || 'Title'}</p>
                  <p className="description whitespace-pre-line">{content || 'content'}</p>
                  <div className={`gap-2 ${cta?.length && 'pt-4'} `}>
                    {cta?.map((item, index) => (
                      <Button
                        type={item?.option === 'primary' ? 'primary' : 'default'}
                        size="middle"
                        className="w-full mb-2"
                      >
                        <div className="text-lg font-semibold">{item?.title || 'Title'}</div>
                      </Button>
                    ))}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OneLifeAppPushPreview
