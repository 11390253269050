import React from 'react'
import Testing from 'Assets/images/Android.png'
import './index.scss'
import { ReactComponent as MessageIcon } from 'Assets/images/icons/AndroidSMS.svg'
import { DownOutlined } from '@ant-design/icons'

const AndroidView = ({ data }) => {
  return (
    <div className="SMS-AndroidView-Wrapper">
      <img src={Testing} style={{ marginLeft: 74 }}></img>
      <div className="SMS-AndroidView-MessageBox">
        <div className="flex justify-between">
          <div className="flex items-center">
            <div className="SMS-AndroidView-MessageBox-Icon">
              <MessageIcon></MessageIcon>
            </div>
            <div
              className="SMS-AndroidView-MessageBox-Title ml-1"
              style={{ fontSize: 11, color: '#516FFF' }}
            >
              Tin nhắn
            </div>

            <div
              className="SMS-AndroidView-MessageBox-Time ml-1"
              style={{ fontSize: 11, color: '#595959' }}
            >
              now
            </div>
          </div>
          <DownOutlined style={{ fontSize: 11, marginRight: 4 }} />
        </div>
        <div>
          <div className="SMS-AndroidView-MessageBox-CompanyName" style={{ fontWeight: 600 }}>
            King Food
          </div>
          <div className="SMS-AndroidView-MessageBox-Content" style={{ wordBreak: 'break-all' }}>
            {data.message_content || 'Enter Description'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AndroidView
