import React from 'react'
import Testing from 'Assets/images/iPhoneX.png'
import './index.scss'
import { ReactComponent as MessageIcon } from 'Assets/images/icons/iosmessage.svg'

const IOSView = ({ data }) => {
  return (
    <div className="SMS-IOSView-Wrapper">
      <img src={Testing} style={{ marginLeft: 60 }}></img>
      <div className="SMS-IOSView-MessageBox">
        <div className="flex justify-between">
          <div className="flex   items-center">
            <div className="SMS-IOSView-MessageBox-Icon">
              <MessageIcon></MessageIcon>
            </div>
            <div
              className="SMS-IOSView-MessageBox-Title ml-1"
              style={{ fontSize: 11, color: '#9c0d0a' }}
            >
              Tin nhắn
            </div>
          </div>
          <div className="SMS-IOSView-MessageBox-Time" style={{ fontSize: 11, color: '#9c0d0a' }}>
            now
          </div>
        </div>
        <div>
          <div className="SMS-IOSView-MessageBox-CompanyName" style={{ fontWeight: 600 }}>
            King Food
          </div>
          <div className="SMS-IOSView-MessageBox-Content" style={{ wordBreak: 'break-all' }}>
            {data.message_content || 'Enter Description'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default IOSView
