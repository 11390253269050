import React, { useMemo, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { message, Select } from 'antd'

import { toCamel } from 'Utils'
import WebPushPreview from './WebPush'
import KaiPassPreview from './Kaipass'
import EmailPreview from './Email/JunoTemplate'
import LinkClicksPreview from './Facebook/LinkClicks'
import PostEngagementPreview from './Facebook/PostEngagement'
import HaraSocialPreview from './Harasocial'
import TargetingItem from 'Pages/Harasiocial/CustomerPortrait/Components/Customer/TargetingItem'
import AppPushPreview from '../AppPush'
import SMS from './SMS'
import OneLifeAppPushPreview from '../OnelifeAppPush'
import ZnsByTemplateId from './ZNS/ZnsByTemplateId'
import ZaloGroupReview from './ZaloGroup'
import ZNSPreview from './ZNS'
import KposPreview from './Kpos'
import TestOnDevice from './TestOnDevice'
import axios from 'Utils/axios'
import ZNSByTemplateId from './ZNSByTemplateId'

const platforms = [
  { label: 'Window', value: 'window' },
  { label: 'Mac OS', value: 'mac_os' },
  { label: 'Android', value: 'android' },
]

const ContentPreview = () => {
  const { selected_placement, content } = useStoreState((state) => state.campaignModule)
  selected_placement?.code == 'sms' && platforms.push({ label: 'iOS', value: 'ios' })

  const [platform, setPlatform] = useState(
    selected_placement?.code == 'sms' ? platforms[3].value : platforms[0].value
  )
  const [loading, setLoading] = useState(false)
  const handleTest = async (payload) => {
    try {
      setLoading(true)
      const res = await axios.post('/tests/campaigns/send-notification', {
        ...payload,
        content: content || {},
      })
      if (res?.data?.failed_count > 0) {
        message.error(
          `Failed to send test, failed number: ${res?.data?.failed_contacts
            ?.map((contact) => contact.phone)
            .join(', ')}`
        )
      }
      if (res?.data?.success_count > 0) {
        message.success('Test sent successfully')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }
  const PreviewScreen = useMemo(() => {
    switch (selected_placement?.code) {
      case 'web_push':
        return <WebPushPreview data={content} platform={platform} />
      case 'kaipass':
        return <KaiPassPreview data={content} />
      case 'ol_app':
        return <OneLifeAppPushPreview data={content} />
      case 'email':
        return <EmailPreview data={content} />
      case 'link_clicks':
        return <LinkClicksPreview data={content} />
      case 'post_engagement':
        return <PostEngagementPreview data={content} />
      case 'hrs_store_farming':
        return (
          <div className="shadow-lg" style={{ width: 380 }}>
            <TargetingItem task={toCamel(content)} expanded></TargetingItem>
          </div>
        )
      case 'harasocial':
        return <HaraSocialPreview data={content} />
      case 'sms':
        return <SMS data={content} platform={platform} />
      case 'zns_by_template_id':
        return <ZNSPreview />
      case 'zalo_group':
        return <ZaloGroupReview />
      case 'kpos':
        return <KposPreview data={content}></KposPreview>
      default:
        return <div className="w-full text-center">No preview available</div>
    }
  }, [content, platform, selected_placement])

  if (!['ol_app', 'zalo_group', 'zns_by_template_id'].includes(selected_placement?.code))
    return (
      <div className="w-full" id="ContentPreview">
        <div className="flex justify-between items-center">
          <span className="font-semibold text-lg">Preview</span>
          {selected_placement?.code == 'web_push' && (
            <div className="platform-select text-brand_primary font-semibold">
              <TestOnDevice onSubmit={handleTest} loading={loading} />
            </div>
          )}
          <div className="platform-select text-brand_primary font-semibold">
            <Select options={platforms} onChange={(value) => setPlatform(value)} value={platform} />
          </div>
        </div>
        <div className="preview-screen">
          <div>{PreviewScreen}</div>
        </div>
      </div>
    )
  return PreviewScreen
}

export default ContentPreview
